import { render, staticRenderFns } from "./MyDialog.vue?vue&type=template&id=aaa172bc&scoped=true"
import script from "./MyDialog.vue?vue&type=script&lang=js"
export * from "./MyDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aaa172bc",
  null
  
)

export default component.exports