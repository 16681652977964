<template>
  <my-alert
    :textFlag="true"
    :value.sync="flag"
    :width="'900'"
    @off="$emit('update:flag', false)"
    :title="'选择人员'"
  >
    <v-card>
      <v-row>
        <v-col cols="12">
          <Select-personnel
            v-if="flag"
            ref="obtain"
            :Selected="deptManagers"
            :only="only"
          ></Select-personnel>
        </v-col>
        <v-col cols="9"></v-col>
        <v-col cols="3">
          <div
            class="d-flex justify-content-between"
            style="padding: 0 20px; margin: 20px 0"
          >
            <v-btn
              outlined
              color="primary"
              @click="$emit('update:flag', false)"
            >取消</v-btn>
            <v-btn
              color="primary"
              @click="ChooseLeadership"
            >确认</v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </my-alert>
</template>

<script>
export default {
  name: "chooseLeadership",
  props: ["flag", "deptManagers", "only"],
  data () {
    return {};
  },

  mounted () { },

  methods: {
    ChooseLeadership () {
      let data = this.$refs.obtain.getListValue();
      this.$emit("setleader", data);
    },
  },
};
</script>

<style lang="scss" scoped></style>
