import request from "../utility/http"
/**
 * @description 
 * @returns 系统维护员工管理的数据
 */
export function getUserList () {
    return request.get('/api/SysUser/GetList')
}

export function getUserList1 () {
    return request.get('/api/SysUser/GetList?userState=1')
}

/**
 * @description 
 * @returns 系统维护员工管理的数据
 */
export function getUser (id) {
    return request.get('/api/SysUser/Get/' + id)
}

/**
 * @description 
 * @returns 系统维护员工管理的新增数据
 */
export function postUserAdd (data) {
    return request.post('/api/SysUser/Add', data)
}

/**
 * @description 
 * @returns 系统维护员工管理的更新数据
 */
export function postUserUpdate (data) {
    return request.post('/api/SysUser/Update', data)
}

/**
 * @description 
 * @returns 系统维护员工管理的重置密码
 */
export function postUserResetPassword (data) {
    return request.post('/api/SysUser/ResetPassword', data)
}

/**
 * @description 
 * @returns 系统维护员工管理的解绑微信
 */
export function postUserUnbindWechat (data) {
    return request.post('/api/SysUser/UnbindWechat', data)
}


/**
 * @description 
 * @returns 获取用户拥有的菜单集合
 */
export function getGetUserMenus(data) {
    return request.get('/api/SysUser/GetUserMenus', data)
}

/**
 * 获取用户的移动端菜单集合
 * @param {*} data 
 * @returns 
 */
export function getUserMenusM(data) {
    return request.get('/api/SysUser/GetUserMenusM', data)
}


/**
 * 修改密码
 * @param {*} data 
 * @returns 
 */
export function ChangePassword (data) {
    return request.postForm('/api/SysUser/ChangePassword', data)
}

/**
 * 设置人员的承接项目能力
 * @param {*} data 
 * @returns 
 */
export function UpdateUndertakeNum (data) {
    return request.post('/api/SysUser/UpdateUndertakeNum', data)
}