<template>
  <v-dialog v-model="datas" scrollable persistent :fullscreen="option.fullscreen" :max-width="option.width">
    <v-card>
      <v-card-title class="headline" style="    justify-content: space-between;"
        ><span>{{ option.title }}</span>
        <v-spacer />
        <v-btn v-if="!option.fullscreen" @click="option.width = option.width + 100" class="close">
          <v-icon v-text="'mdi-plus'" />
        </v-btn>
        <v-btn v-if="!option.fullscreen" @click="option.width = option.width - 100" class="close">
          <v-icon v-text="'mdi-minus'" />
        </v-btn>
        <v-btn @click="changeDialog" class="close">
          <v-icon v-text="'mdi-close'" />
        </v-btn>
      </v-card-title>
      <!-- 需要传递option -->
      <v-divider></v-divider>
      <v-card-text id="myDialog-text" :style="{ 'background-color': option.backgroundColor }">
        <div ref="contentdate">
          <!-- <v-col cols="12" class="pt-2"></v-col> -->
          <slot name="content"></slot>
          <!-- <v-col cols="12" class="pt-8"></v-col> -->
        </div>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions v-if="$slots.bottom">
        <v-col cols="12">
          <div class="flex" style="justify-content:center">
            <slot name="bottom"> </slot>
          </div>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）

//例如：import 《组件名称》 from '《组件路径》';

export default {
  name: 'myDialog',
  props: {
    option: {
      value: {
        type: Boolean,
      },
      ondialog: {
        type: Boolean,
      },
      width: {
        type: Number,
      },
      title: {
        type: String,
      },
      notTitle: {
        type: Boolean,
        default: false,
      },
      changeDialog: {
        type: Function,
      },
      backgroundColor: {
        type: String,
        default: '#fff',
      },
      fullscreen: {
        type: Boolean,
        default: false,
      },
    },
  },
  inheritAttrs: false,
  model: {
    prop: ['value' || 'ondialog'],
    event: 'click',
  },
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {}
  },
  //监听属性 类似于data概念
  computed: {
    datas: {
      set: function() {},
      get: function() {
        return this.moedls()
      },
    },
  },

  //方法集合
  methods: {
    moedls() {
      if (this.option.value == true) {
        return this.option.value
      } else if (this.option.ondialog == true) {
        return this.option.ondialog
      }
    },
    async changeDialog() {
      this.option.value = false
      this.option.ondialog = false
      this.option.text = false
    },

    // tabled() {
    //   this.$emit("update:dialog", false);
    // },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  async created() {},
  beforeMount() {
    this.$nextTick(() => {
      if (this.option.width <= 1200) {
        this.$refs.contentdate.style.padding = '0 12px'
      } else {
        this.$refs.contentdate.style.padding = '0 12px'
      }
    })
  }, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeUpdate() {}, //生命周期 - 更新之前
}
</script>
<style lang="scss" scoped></style>
