<template>
  <div v-resize="onResize" style="white-space: nowrap">
    <v-data-table
      v-bind="$attrs"
      v-on="$listeners"
      :items="items"
      :headers="headers"
      :search="search"
      ref="VDataTable"
      :page.sync="page"
      @current-items="currentItems"
      :footer-props="{
        itemsPerPageOptions: itemsPerPageOptions,
      }"
      @page-count="pageCount = $event"
      :fixed-header="`fixed-header`"
      :height="tableHeight"
      :options.sync="paginationSync"
      :hide-default-footer="$attrs['disable-pagination']"
    >
      <template v-slot:top v-if="!hideTop">
        <div class="d-flex justify-content-between table-head">
          <div :style="`align-items: center;overflow-y:auto;overflow-x:hidden`">
            <div class="topLeft" ref="seachLis" style="font-size: 14px; width: auto">
              <div class="d-flex float-left">
                <div class="seach-text">搜索</div>
                <div class="ml-1 mr-3">
                  <v-text-field dense style="width: 130px" v-model="search"></v-text-field>
                </div>
              </div>
              <slot name="topLeft"></slot>
            </div>
          </div>

          <div class="d-flex justify-end topRight" style="width: auto">
            <v-tooltip top v-if="$attrs.excelName">
              <template v-slot:activator="{ on, attrs }">
                <v-btn style="padding: 0 4px !important; text-transform: none !important" v-bind="attrs" v-on="on" outlined color="primary" @click="ExportTable"> <v-icon>mdi-export</v-icon>Excel </v-btn>
              </template>
              <span>导出Excel</span>
            </v-tooltip>

            <slot name="topRight"></slot>
          </div>
        </div>
      </template>

      <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
        <slot :name="name" v-bind="data"></slot>
      </template>

      <template v-slot:[`foot`]>
        <slot name="bottom">
          <tfoot>
            <tr v-if="isShowSum()" class="total-line">
              <td class="po-left text-start font-weight-bold">合计</td>
              <td v-if="$attrs.selected"></td>
              <td v-for="(item, index) in totalList" :key="index" class="font-weight-bold text-end">
                {{ item }}
              </td>
            </tr>
          </tfoot>
        </slot>
      </template>
    </v-data-table>
    <v-row class="m-30" v-if="!$attrs['disable-pagination']">
      <v-col></v-col>
      <v-col cols="8">
        <v-pagination v-model="page" :length="pageCount" circle></v-pagination>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { antiShake } from '@/utility/antiShake'
import { mapGetters } from 'vuex'

export default {
  name: 'FiltDataTable',
  props: {
    hideTop: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => {
        ;[]
      },
    },
    headers: {
      type: Array,
      default: () => {
        ;[]
      },
    },
    'fixed-header': {
      type: Boolean,
      default: true,
    },
    tdHeight: {
      //td的高度，默认为36
      type: Number,
      default: 36,
    },
  },
  data() {
    return {
      //items: [], //渲染列表数据的值
      search: '',
      itemsList: [],
      page: 1, //page页
      //showpage: 10, //每页的数据条数
      pageCount: 0, //总共多少页
      windowSize: {
        x: 0,
        y: 0,
      },
      totalList: [], //合计行
      itemsPerPageOptions: [10, 20, 30, -1],
      paginationSync: { itemsPerPage: 20 },
      canShowCount: 0, //一页可以显示多少行
      currentShowCount: 0, //实际显示了多少行
    }
  },
  async created() {
    this.setPerPage()
  },

  watch: {
    headers() {
      this.setPerPage()
      this.$nextTick(() => {
        this.dataTabSeach()
      })
    },
  },
  beforeUpdate() {},
  methods: {
    setPerPage() {
      if (!this.$attrs['disable-pagination']) {
        this.windowSize.x = window.innerWidth
        this.windowSize.y = window.innerHeight
        let appBarHeight = this.appBarshow ? 48 : 0
        let totalHeight = 0 //合计行高度
        if (this.isShowSum() || this.$scopedSlots.bottom) {
          totalHeight = 36
        }
        if (this.$attrs.height && this.$attrs.height != 'auto') {
          this.paginationSync.itemsPerPage = (this.$attrs.height - 56 - totalHeight) / this.tdHeight
        } else if (this.hideTop) {
          this.paginationSync.itemsPerPage = (this.windowSize.y - 185 - appBarHeight - totalHeight) / this.tdHeight
        } else {
          this.paginationSync.itemsPerPage = (this.windowSize.y - 255 - appBarHeight - totalHeight) / this.tdHeight
        }
        this.canShowCount = this.paginationSync.itemsPerPage = Math.floor(this.paginationSync.itemsPerPage)
        if (this.itemsPerPageOptions.includes(this.canShowCount)) return
        for (let i = 0; i < this.itemsPerPageOptions; i++) {}
        this.itemsPerPageOptions.forEach((item, index) => {
          if (this.canShowCount < item) {
            this.itemsPerPageOptions.splice(index, 0, this.canShowCount)
          }
        })
      }
    },
    currentItems(items) {
      this.currentShowCount = items.length
      if (this.$refs.VDataTable && this.items) {
        this.itemsList = this.$refs.VDataTable.customFilterWithColumns(this.items, this.search)
        this.setTotal()
      }

      this.$emit('current-items', items)
    },
    isShowSum() {
      let isShow = false
      this.headers.forEach((item) => {
        if (item.sum == true) {
          isShow = true
        }
      })
      return isShow
    },
    setTotal() {
      if (!this.isShowSum()) {
        return
      }
      this.headers.forEach((header, index) => {
        this.totalList[index] = ''
        if (header.sum == true) {
          this.itemsList.forEach((item) => {
            if (this.totalList[index] != '') {
              this.totalList[index] = this.counted(`${item[header.value] ? item[header.value] : 0}+${this.totalList[index]}`)
            } else {
              this.totalList[index] = item[header.value] ? item[header.value] : 0
            }
          })
        }
        if (header.isFormat != false) {
          if (this.totalList[index] !== null && this.totalList[index] !== '') {
            this.totalList[index] = this.$options.filters.formatMoney(this.totalList[index])
          }
        }
      })
      this.totalList.shift()
    },
    counted(str) {
      return this.$math.number(this.$math.evaluate(str))
    },
    ExportTable() {
      import('@/utility/Export2Excel').then((excel) => {
        excel.export_table_to_excel(this.$refs.VDataTable.$el, this.$attrs.excelName)
      })
      return
    },
    dataTabSeach() {
      if (this.$refs.seachLis.children) {
        Array.from(this.$refs.seachLis.children).forEach((element) => {
          element.className += ' d-flex float-left'
          element.style.height = '34px'
          if (String(element.className).includes('v-input')) {
            if (!element.style.width) {
              element.style.width = '120px'
            }
            if (String(element.className).includes('v-menu')) {
              Array.from(element.children).forEach((val) => {
                if (String(val.className).includes('v-input')) {
                  if (!val.style.width) {
                    val.style.width = '200px'
                  }
                }
              })
            }
          } else {
            Array.from(element.children).forEach((item) => {
              if (String(item.className).includes('v-input')) {
                if (!item.style.width) {
                  item.style.width = '120px'
                }
              }
              if (String(item.className).includes('v-menu')) {
                Array.from(item.children).forEach((val) => {
                  if (String(val.className).includes('v-input')) {
                    if (!val.style.width) {
                      val.style.width = '200px'
                    }
                  }
                })
              }
            })
          }
          if (element.children[0]) {
            element.children[0].style.marginRight = '2px'
          }
        })
        this.$refs.seachLis.style = 'font-size:14px;line-height:28px;'
        setTimeout(() => {
          let widtss = 0
          if (this.$refs.seachLis.children) {
            Array.from(this.$refs.seachLis?.children).forEach((element) => {
              widtss += element?.offsetWidth - 0.5
            })
          }

          if (widtss <= this.$refs.seachLis?.offsetWidth) {
            this.$refs.seachLis.style.marginTop = '21px'
          }
        })
      }
    },
    onResize() {
      this.debounceResize(this)
    },
    //resize防抖，传入this
    debounceResize: antiShake(
      (obj) => {
        obj.windowSize.x = window.innerWidth
        obj.windowSize.y = window.innerHeight
      },
      200,
      true
    ),
  },
  async mounted() {
    this.$nextTick(() => {
      this.dataTabSeach()
    })
  },
  computed: {
    ...mapGetters(['appBarshow']),
    tableHeight() {
      if (this.$attrs['disable-pagination']) {
        return
      }
      if (this.$attrs.height) {
        return this.$attrs.height
      }
      let totalHeight = 0 //合计行高度
      if (this.isShowSum() || this.$scopedSlots.bottom) {
        totalHeight = 36
      }
      return (Math.min(this.canShowCount, this.currentShowCount) || 1) * this.tdHeight + 56 + totalHeight
    },
  },
}
</script>

<style lang="scss">
.table-head {
  margin: 0 12px;
  height: auto;
  align-items: center;
  min-height: 70px;
}
.topLeft:after,
.topLeft:before {
  content: '';
  display: table;
}

.topLeft:after {
  clear: both;
}

.topLeft {
  .v-text-field .v-input__control {
    margin: 0 !important;
  }

  .v-text-field {
    margin: 0 !important;
  }
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  color: #333;
  font-size: 14px;
  font-weight: 700 !important;
}

tbody tr:nth-of-type(even) {
  background-color: #fafafa;
}

.topLeft .v-input--radio-group {
  margin: 0 !important;
}

/* .title2 {
  border: 1px solid #eef1f5;
  padding: 10px 10px 10px !important;
  margin: 0 24px 0 !important;
}
.border {
  font-weight: normal !important;
} */

.m-30 {
  margin-top: -50.5px !important;
  margin-right: 10px;
}

.v-data-footer__select {
  margin-left: 0 !important;
}
.v-data-footer__select .v-select {
  margin: 12px 0 14px 20px !important;
}
.v-data-footer__pagination {
  margin: 0 32px 0 8px !important;
}
.v-data-footer__icons-before,
.v-data-footer__icons-after {
  display: none;
}

/* .title {
  background-color: #f5f5f5;
  border: 1px solid #e7e7e7;
} */

.theme--light.v-data-table {
  color: #2e2c2c;
}

.v-data-table > .table-head > .topRight > button {
  margin-left: 10px;
}

.total-line {
  position: sticky;
  bottom: 0;
  z-index: 2;
  background-color: #ffffff !important;
  td {
    height: 36px !important;
    background-color: #ffffff !important;
    border-top: thin solid rgba(0, 0, 0, 0.12);
    // border-bottom: thin solid rgba(0, 0, 0, 0.12);
    font-size: 14px;
  }
}
.v-application--is-ltr .v-data-table--fixed-header .v-data-footer {
  margin-right: 0 !important;
}
th,
td {
  padding: 0 8px !important;
}
th:first-child,
td:first-child {
  padding: 0 8px 0 12px !important;
}
th:last-child,
td:last-child {
  padding: 0 12px 0 8px !important;
}
.v-pagination {
  justify-content: right;
}
.v-data-footer {
  padding: 0 12px;
}
table > tfoot > tr > td:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
}
</style>
