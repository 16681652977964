const EN_LOCALE_ONLY = process.env.EN_LOCALE_ONLY === 'true'
const IN_BROWSER = typeof window !== 'undefined'
const IS_DEBUG = process.env.DEBUG === 'true'
const IS_PROD = process.env.NODE_ENV === 'production'
let apiBaseURL = ''
const OSSCallbackUrl = process.env.NODE_ENV == 'development' ? 'http://api.erp.cct-design.cn/api/SysAttachment/OSSCallback' : '/api/SysAttachment/OSSCallback'

module.exports = {
  EN_LOCALE_ONLY,
  IN_BROWSER,
  IS_DEBUG,
  IS_PROD,
  apiBaseURL,
  OSSCallbackUrl,
}
