<template>
  <v-dialog
    v-model="option.print"
    scrollable
    persistent
    :max-width="option.width"
  >
    <v-card>
      <v-card-title
        class="headline"
        style="    justify-content: space-between;"
      ><span>{{ option.title }}</span>
        <v-btn
          @click="PrintDialog"
          class="close"
        >
          <v-icon v-text="'mdi-close'" />
        </v-btn>
      </v-card-title>
      <!-- 需要传递option -->
      <v-divider></v-divider>
      <v-card-text>
        <div
          style=" display: flex
  "
          :class="option.width == 1200 ? 'bigl' : 'soml'"
        >
          <div
            id="box-sing"
            class="somw"
            ref="reportPrint"
          >
            <div class="content">
              <h2>
                {{ option.title }}
              </h2>

              <slot name="content"></slot>
            </div>
          </div>
        </div>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-col cols="12">
          <div style="text-align: center;margin:5px">
            <v-btn
              slot="reference"
              class="primary"
              style="margin-right:10px       "
              @click="changePrint"
              type="success"
            >打印
            </v-btn>

            <v-btn
              depressed
              color="primary"
              @click="PrintDialog"
            >
              取消
            </v-btn>
          </div>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）

//例如：import 《组件名称》 from '《组件路径》';

export default {
  name: "MyPrint",

  props: {
    option: {
      print: {
        type: Boolean,
      },
      value: {
        type: Boolean,
      },
      width: {
        type: Number,
      },
      title: {
        type: String,
      },
      PrintDialog: {
        type: Function,
      },
      changePrint: {
        type: Function,
      },
    },
  },
  inheritAttrs: false,
  model: {
    prop: "print",
    event: "click",
  },
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    //这里存放数据
    return {};
  },
  //监听属性 类似于data概念
  computed: {},

  //方法集合
  methods: {
    async PrintDialog () {
      this.option.print = false;
    },
    changePrint () {
      printJS({
        printable: "box-sing",
        type: "html",
        honorMarginPadding: false,
        targetStyles: ["*"],
        font_size: null,
        font: null,
      });
    },

    // tabled() {
    //   this.$emit("update:dialog", false);
    // },
  },
  beforeCreate () { }, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  async created () { },
  beforeMount () { }, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  beforeUpdate () { }, //生命周期 - 更新之前
};
</script>
<style lang="scss" scoped>
.soml {
  margin-left: 124px;
}
.bigl {
  margin-left: 226px;
}
.somw {
  width: 640px;
  margin-left: 32px;
}
.bigw {
  margin-left: -5px;
  width: 710px;
}

#box-sing {
  display: flex;
  justify-content: center;
  text-align: center;
  color: #666;
}
.content {
  width: 100%;
  font-weight: 400;
  h2 {
    margin: 30px 0;
    font-weight: 500;
  }
}
</style>
