import { getAreaList, getBrandList, getCategoryList, getCodeList, GetMallTypeList, getMyCart, getMyProjects, getSceneCategoryList, getStyleLabelsList, getSupplierList, getSupplierTypeCategoryList, getSupplierTypeList, getWarehouseLocationList } from '@/api/productLibrary'

const state = {
  Option: {
    AreaList: [],
    CategoryList: [],
    CodeList: [],
    SupplierTypeList: [],
    WarehouseLocationList: [],
    SceneCategoryList: [], //场景的标签列表
    StyleLabelsList: [],
    BrandList: [],
    SupplierList: [],
    MallTypeList: [],
  },
  MyCart: [],
  MyProjects: [],
}
const getters = {
  objSupplierType: (state) => {
    let result = {}
    state.Option.SupplierTypeList.forEach((item) => {
      result[item.codeId] = item.codeName
    })
    return result
  },
  objWarehouseLocation: (state) => {
    let result = {}
    state.Option.WarehouseLocationList.forEach((item) => {
      result[item.codeId] = item.codeName
    })
    return result
  },
}
const mutations = {
  setOption(state, data) {
    state.Option = data
  },
  setMyCart(state, data) {
    state.MyCart = data
  },
  setMyProjects(state, data) {
    state.MyProjects = data
  },
}
const actions = {
  async getOption({ commit }) {
    let result = {}
    //if (state.Option.WarehouseLocationList.length > 0) return
    result.WarehouseLocationList = await getWarehouseLocationList()
    result.AreaList = await getAreaList()
    result.SupplierTypeList = await getSupplierTypeList()
    result.CategoryList = await getCategoryList()
    result.CodeList = await getCodeList()
    result.SceneCategoryList = await getSceneCategoryList()
    result.StyleLabelsList = await getStyleLabelsList()
    result.BrandList = await getBrandList()
    result.SupplierList = await getSupplierList('', true)
    result.MallTypeList = await GetMallTypeList()
    result = { ...result, ...(await getSupplierTypeCategoryList()) }
    commit('setOption', result)
  },
  async getMyCart({ commit }) {
    let result = []
    result = await getMyCart()
    result.forEach((item) => {
      if (!(item.count > item.quantityInStock && item.warehouseLocation === '10')) item.check = true
    })
    commit('setMyCart', result)
  },
  async getMyProjects({ commit }) {
    if (state.MyProjects.length > 0) return
    let result = []
    result = await getMyProjects()
    commit('setMyProjects', result)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
