<template>
  <my-alert
    :textFlag="true"
    :value.sync="Treeflag"
    :width="'600'"
    :title="'选择部门'"
    @off="$emit('update:Treeflag',false)"
  >
    <v-row>
      <v-col cols="12">
        <tree @transmitData="setccountTo" :propgetList="sysdeptGetList" Name="deptName"></tree>
      </v-col>
      <v-col cols="7"></v-col>
      <v-col cols="5">
        <div
          class="d-flex justify-content-between"
          style="padding: 0 20px; margin: 20px 0"
        >
          <v-btn color="primary" @click="setaccountToDept">确定</v-btn>
          <v-btn @click="$emit('update:Treeflag',false)">取消</v-btn>
        </div>
      </v-col>
    </v-row>
  </my-alert>
</template>

<script>
import {sysdeptGetList} from "../../api/sysdept"
export default {
  name: "selectDepartment",
  props: ["Treeflag", "deptManagers"],
  data() {
    return {
        accountToDept:null
    };
  },
  computed:{
    sysdeptGetList(){
      return sysdeptGetList
    }
  },

  methods: {
    setccountTo(item) {
      this.accountToDept = item;
    },
    setaccountToDept(){
        this.$emit("setaccountToDept",this.accountToDept)
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-list-item-group {
  border: 1px solid #ccc;
}
// ::v-deep .v-list-item--link {
//   border-bottom: 1px solid #ccc;
// }  
</style>