
import Vue from "vue";
import Router from "vue-router";
import { getGetUserMenus } from "../api/SysUser";
import qs from "qs";
Vue.use(Router);
const emptyPage = () => import("../views/systemSetup/emptyPage.vue");
const login = () => import("../views/login/login.vue");
const Layout = () => import("../layout/default/Index.vue");
// const move = () => import("../views/move");

async function a () {
  let menuList = []
  menuList = await getGetUserMenus();
  if (!menuList) {
    return Promise.resolve([]);
  }
  menuList?.forEach((item, index) => {
    item.path = item.menuURL.split('?')[0];
    item.meta = { name: item.menuName };
    item.component = item.menuComponent ? () => import(`@/views${item.menuComponent}.vue`) : emptyPage
    item.props = qs.parse(item.menuURL.split('?')[1]);
    item.children = menuList?.filter(m => m.parentId == item.id)
  });
  let asyncRoutes = menuList?.filter(m => m.parentId == null)
  let obj = {
    path: "/",
    component: Layout,
    name: "",
    meta: { name: ""},
    menuIcon: "mdi-view-dashboard",
    children: asyncRoutes,
  };
  let arr = [obj];
  return Promise.resolve(arr);
}
export const constantRoutes = [
  {
    path: "/404",
    meta: { name: "404"},
    component: () => import("@/views/error-page/404"),
    hidden: true,
  },
  {
    path: "/login",
    name: "login",
    meta: { name: "login"},
    component: login,
  },
  {
    path: "/emptyPage",
    name: "emptyPage",
    meta: { name: "emptyPage"},
    component: emptyPage,
  },
  // {
  //   path: "/move",
  //   name: "move",
  //   component: move,
  // },
];



export { a };

const createRouter = () =>
  new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    // 切换到上一个路由的时候让滚动条y等于零
    routes: constantRoutes,
  });

const router = createRouter();

const originalPush = Router.prototype.push;
Router.prototype.push = function push (location) {
  originalPush.call(this, location).catch((err) => err);
  return
};
// if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
//   console.log();
// } else {
// }
export default router;
